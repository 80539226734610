$(document).on('click', 'button[id^="deletePhotoButton_"]', function () {
    var id = parseInt(this.id.replace("deletePhotoButton_", ""));
    $('#modalImageName').attr("src", $('#deletePhotoButton_' + id).data('valueImage'));
    $('#photoDeleteForm').attr("action", '/objects/' + $('#deletePhotoButton_' + id).data('valueIdobject') + '/photo/' + $('#deletePhotoButton_' + id).data('valueIdphoto'));
});

$(document).on('submit', '#photoDeleteForm', function (event) {
//        $('#photoDeleteForm').submit(function (event) {
    event.preventDefault();
    $.ajax({
        headers: { 'X-CSRF-Token' : $('input[name=_token]').val() },
        type: "DELETE",
        url: $(this).attr('action'),
        success: function (response) {

            $('#photogallery').load('/objects/' + response.idObject + '/photo/show');
            $('#destroyPhotoModal').modal('hide');
            $('.modal-backdrop').remove();

        }
    });
});

var dropzonePhotoGallery = 0;
Dropzone.options.photoDropzone = {
    dictDefaultMessage: 'Ziehen Sie zum Hochladen die Fotos einfach in dieses Feld.',
    dictInvalidFileType: 'Das Format wird nicht unterstützt.',
    dictFileTooBig: 'Die Datei ist zu groß.',
    paramName: 'photo',
    uploadMultiple: false,
    parallelUploads: 20,
    maxFilesize: 8,
    acceptedFiles: '.jpg, .jpeg, .png, .gif',
    init: function () {
        // Execute when file uploads are complete
        this.on("complete", function () {
            // If all files have been uploaded
            if (this.getQueuedFiles().length == 0 && this.getUploadingFiles().length == 0) {
                var _this = this;
                // Remove all files
                _this.removeAllFiles();
                $('#photogallery').load('/objects/' + dropzonePhotoGallery + '/photo/show');
            }
        });

    },
    success: function (file, response) {
        dropzonePhotoGallery =  response.idObject;
    },

};