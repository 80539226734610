$("#address").bind('change', function () {
    if ($(this).val() == "Firma") {
        $('#companyDiv').slideDown();
    }
    else {
        $('#companyname').val('');
        $('#vat').val('');
        $('#companyDiv').slideUp();
    }
});


Dropzone.options.avatarDropzone = {
    dictDefaultMessage: 'Ziehen Sie Ihr Profilfoto einfach in dieses Feld.',
    dictInvalidFileType: 'Das Format wird nicht unterstützt.',
    dictFileTooBig: 'Die Datei ist zu groß.',
    paramName: 'avatar',
    uploadMultiple: false,
    parallelUploads: 1,
    maxFiles:1,
    maxFilesize: 8,
    acceptedFiles: '.jpg, .jpeg, .png, .gif',
    init: function () {
        // Execute when file uploads are complete
        this.on("complete", function () {
            // If all files have been uploaded
            if (this.getQueuedFiles().length == 0 && this.getUploadingFiles().length == 0) {
                var _this = this;
                // Remove all files
                _this.removeAllFiles();
            }
        });

    },
    success: function (file, response) {

        $('#avatarPhoto').attr('src', '/avatars/thumbs/thumb_120_' + response.imgName);
        $('#menulogo').attr('src', '/avatars/thumbs/thumb_120_' + response.imgName);

//            console.log(response);
    }
};