$("#zipcode").bind("keyup blur change", function () {
    var el = $(this);
    if ((el.val().length == 5)) {
        if (is_int(el.val())) {

            //@todo performance: check if value changed, and only then fire ajax-request!

            //@todo add loading indicator while ajax-call is running

            $.ajax({
                type: 'GET',
                url: '/api/zipcode/' + el.val(),
                success: function (result) {
                    $('#district').val(result.district).prop('disabled', false);
                    $('#city').val(result.city).prop('disabled', false);
                    $('#id_zipcode').val(result.id_zipcode);

                    if (!result.city) {
                        $('#divzipcode').addClass('has-warning').removeClass('has-success').removeClass('has-error');
                        $('#divcity').removeClass('has-success');
                        $('#divdistrict').removeClass('has-success');

                    } else {
                        $('#divzipcode').removeClass('has-warning').removeClass('has-error').addClass('has-success');
                        $('#divcity').addClass('has-success');
                        $('#divdistrict').addClass('has-success');
                    }
                }
            });

        }
        else {
            $('#divzipcode').removeClass('has-warning').removeClass('has-success').addClass('has-error');
        }

    }
    else {
        $('#district').val('').prop('disabled', true);
        $('#city').val('').prop('disabled', true);
    }

});


function is_int(value) {
    if ((parseFloat(value) == parseInt(value)) && !isNaN(value)) {
        return true;
    } else {
        return false;
    }
}


$('#objectForm').submit(function () {
    $('#district').prop('disabled', false);
    $('#city').prop('disabled', false);
});